import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from './pages/Home';
import Invoices from './pages/Invoices';
import SelectPlan from './pages/SelectPlan';
import Profile from "./pages/Profile";
import Style from "./pages/Style";
import AppNavigation from "./components/AppNavigation";
import QueuedJobs from "./pages/QueuedJobs";
import Settings from "./pages/Settings";

const AppRoutes = ({ store }) => {
    const [appNavVisible, setAppNavVisible] = useState(true);
    const location = useLocation();
    useEffect(() => {
        setAppNavVisible(location.pathname === "/");
    }, [location]);

    return (
        <>
            {appNavVisible &&
                <AppNavigation store={store}></AppNavigation>
            }
            <Routes>
                <Route exact path="/" element={<Home store={store} />} />
                <Route exact path="/links" element={<Home store={store} />} />
                <Route exact path="/profile" element={<Profile store={store} />} />
                <Route exact path="/style" element={<Style store={store} />} />
                <Route exact path="/settings" element={<Settings store={store} />} />
                <Route exact path="/upgrade-plan" element={<SelectPlan />} />
                <Route exact path="/invoices" element={<Invoices />} />
                <Route exact path="/queued-jobs" element={<QueuedJobs store={store} />} />
            </Routes>
        </>

    );
}
export default AppRoutes
