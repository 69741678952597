import { Flex, FlexItem, Panel, PillTabs } from "@bigcommerce/big-design";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Quota from "./Quota";

const AppNavigation = (props) => {
    const [activePills, setActivePills] = useState([]);
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        let pathname = location.pathname.replace("/", "");
        if (pathname === "") {
            pathname = "description-writer";
        }
        setActivePills(pathname);
    }, [location]);

    const pillItems = [
        { title: 'Links', id: 'links' },
        { title: 'Profile', id: 'profile' },
        { title: 'Style', id: 'style' },
        { title: 'Settings', id: 'settings' },
    ];

    const onPillClick = (id) => {
        setActivePills([id]);
        navigate("/" + id);
    }
    return (
        <Panel margin="small">
            <Flex justifyContent={"space-between"}>
                <FlexItem flexBasis={"65%"}>
                    <PillTabs activePills={activePills} items={pillItems} onPillClick={onPillClick} />
                </FlexItem>
                <FlexItem flexShrink={3}>
                    <Quota store={props.store}></Quota>
                </FlexItem>
            </Flex>
        </Panel>
    );
}
export default AppNavigation;
