import React, { useEffect, useState } from 'react';
import { Message } from '@bigcommerce/big-design';
import Links from './Links';
import SelectPlan from './SelectPlan';
import PageLoading from '../components/PageLoading';
import { StoreClient } from '../services/StoreClient';

const Home = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [store, setStore] = useState();
    const [planId, setPlanId] = useState();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const store = await StoreClient.getStore();
                setStore(store);
                setPlanId(store.subscription.planId);
            }
            catch {
                setError("Unable to authenticate BigCommerce store");
            }
            finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    // useEffect(() => {
    //     setStore(props.store);
    //     if (props.store.subscription) {
    //         setPlanId(props.store.subscription.planId);
    //     }
    // }, []);

    const handlePlanSelected = (plan) => {
        store.subscription = {
            planId: plan
        }
        setStore(store);
        setPlanId(plan);
    }

    return (
        <>
            {loading &&
                <PageLoading />
            }
            {!loading && error === "" && store &&
                <>
                    {planId === undefined &&
                        <SelectPlan store={store} onPlanSelected={(e) => handlePlanSelected(e)}></SelectPlan>
                    }
                    {planId &&
                        <Links store={store}></Links>
                    }
                </>
            }
            {!loading && error !== "" &&
                <Message type="error" header="Error" messages={[{ text: error }]} marginVertical="medium" />
            }
        </>
    );
}

export default Home;
